<template>
  <div class="pt-5">
    <div class="relative pt-20 bg-white mb-5 rounded-2xl pb-5">
      <div class="flex items-center absolute left-0 right-0 -top-10">
        <div class="flex gap-2 flex-1 items-center font-bold pt-14 pl-4">
          <JucIcon class="h-10 w-10" />
          <span class="juc-text-color">JUC</span>
          <span>{{ editionName }}</span>
        </div>
        <TeamAvatar :team="team" :with-shadow="true" />
        <div class=" text-primary flex-1 pt-14 pr-4 font-semibold flex items-center">
          <fw-button
            v-if="team.status === 'draft_2' && canSubmit"
            type="primary"
            class="ml-auto"
            @click.native="$emit('submit-team')"
          >
            Submeter inscrição
          </fw-button>
          <div v-else-if="team.status === 'approved'" class="flex gap-4 text-primary items-center ml-auto">
            Aprovada
            <fw-icon-shield-check class="text-primary h-10 w-10" />
          </div>
          <div v-else-if="team.status === 'submitted'" class="flex gap-4 juc-text-color items-center ml-auto">
            Aguarda aprovação
            <fw-icon-exclamation-triangle class="h-9 w-9 juc-text-color" />
          </div>
          <div v-else-if="team.status === 'cancelled'" class="text-sm text-gray-500 ml-auto">Cancelada</div>
          <div v-else-if="team.status === 'draft_1'" class="text-sm text-gray-500 ml-auto">Rascunho</div>
          <div v-else-if="team.status === 'denied'" class="flex gap-4 text-red-600 items-center ml-auto">
            Recusada
            <fw-icon-exclamation-triangle class="h-9 w-9 text-red-500" />
          </div>
        </div>
      </div>
      <div class="flex justify-center relative pb-10">
        <div
          class="font-bold text-center border-2 rounded-full px-4 py-1"
          :style="{
            color: team.color ?? '#777777',
            borderColor: team.color ?? '#777777'
          }"
        >
          <v-clamp v-if="team?.name" autoresize :max-lines="1"> {{ team?.name }}</v-clamp>
          <span v-else class="overflow-ellipsis overflow-hidden whitespace-nowrap">Sem nome</span>
        </div>
      </div>
      <div class="flex gap-8 justify-center text-gray-600 text-center">
        <div class="flex flex-col">
          <div class="text-xs mb-2">Liga</div>
          <div class="font-bold">{{ league.title?.[language] }}</div>
          <fw-button class="mx-auto" type="link" @click.native="open('league')">Abrir</fw-button>
        </div>
        <div class="flex flex-col">
          <div class="text-xs mb-2">Torneio</div>
          <div class="font-bold">{{ tournament.title?.[language] }}</div>
          <fw-button class="mx-auto" type="link" @click.native="open('tournament')">Abrir</fw-button>
        </div>
        <div v-if="sport.modality && sport.category" class="flex flex-col">
          <div class="text-xs mb-2">Modalidade</div>
          <div class="font-bold">
            <span>{{ sport.modality.title?.[language] }}</span>
            <span v-if="sport.category?.[language]" class="text-sm text-gray-500 ml-1"
              >({{ sport.category?.[language] }})</span
            >
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="text-xs">Género</div>
          <div class="font-bold">
            {{ tournament.gender == 'M' ? 'Masculino' : tournament.gender == 'F' ? 'Feminino' : 'Misto' }}
          </div>
        </div>
        <div class="flex flex-col gap-2">
          <div class="text-xs">Equipa</div>
          <div class="font-bold">
            {{ team?.players?.length }} {{ team?.players?.length == 1 ? 'elemento' : 'elementos' }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center items-center gap-1 text-xs text-gray-400">
      <div v-if="team.created_at">Criada em {{ team.created_at | formatDateTime }}</div>
      <div v-if="team.submitted_at">Submetida em {{ team.submitted_at | formatDateTime }}</div>
      <div v-if="team.confirmed_at">Confirmada em {{ team.submitted_at | formatDateTime }}</div>
      <div v-if="team.denied_at">Rejeitada em {{ team.submitted_at | formatDateTime }}</div>
    </div>
    <div v-if="team.status === 'draft_2' && canEdit" class="my-5">
      <fw-panel-info type="orange" boxed centered icon
        ><span class="font-bold">A tua equipa ainda não foi submetida e não está a ser considerada.</span><br />Quando
        todos os convites tiverem sido aceites (no caso de equipas coletivas), por favor, submete a inscrição da tua
        equipa.</fw-panel-info
      >
    </div>
  </div>
</template>
<script>
import JucIcon from '../icons/JUCIcon'
import TeamAvatar from '@/components/TeamAvatar'
export default {
  components: {
    JucIcon,
    TeamAvatar
  },
  props: {
    team: {
      type: Object,
      required: true
    },
    edition: {
      type: Object,
      required: true
    },
    league: {
      type: Object,
      required: true
    },
    tournament: {
      type: Object,
      required: true
    },
    sport: {
      type: Object,
      required: true
    },
    canSubmit: {
      type: Boolean,
      default: false
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    editionName() {
      return this.edition?.name ?? 'Edição sem nome'
    },
    language() {
      return this.$store.state.language || 'pt'
    }
  },
  methods: {
    open(what) {
      if (what === 'league') {
        this.$router.push({
          name: 'league-view',
          params: {
            editionKey: this.edition.key,
            key: this.league.key
          }
        })
      } else if (what === 'tournament') {
        this.$router.push({
          name: 'tournament-view',
          params: {
            key: this.league.key,
            tournamentKey: this.tournament.key,
            editionKey: this.edition.key,
            sportKey: this.tournament.sport_key
          }
        })
      }
    }
  }
}
</script>
