<template>
  <div v-if="team" class="relative p-5">
    <PanelTeamHeader v-if="showHeader" :team="team"></PanelTeamHeader>
    <fw-panel :title="'Elementos da equipa'" :counter="totalPlayers" featured>
      <template #toolbar>
        <fw-button v-if="validations.can_edit_team" type="link" @click.native="$emit('add-player')"
          >Adicionar</fw-button
        >
      </template>
    </fw-panel>

    <fw-panel :title="'Confirmados'" class="mb-5 mt-3" :counter="team.players?.length ?? 0">
      <div class="flex gap-5 flex-wrap">
        <div v-for="player in team.players" :key="player.key" class="bg-white rounded-lg p-2 flex flex-col gap-4 w-60">
          <div class="flex h-7">
            <div v-if="team.leader.key == player.key" class="flex-1 flex gap-1 items-center">
              <fw-icon-start-smile-solid class="text-primary h-5 w-5" />
              <span class="font-bold leading-5 my-1.5">Líder</span>
            </div>
            <b-dropdown
              v-if="validations.can_edit_team && (player.key != user.key || team.leader.key != player.key)"
              aria-role="list"
              class="ml-auto"
            >
              <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Ações">
                <fw-icon-more class="w-5 h-5" />
              </fw-button-dropdown>
              <b-dropdown-item
                v-if="team.leader.key != player.key && validations.can_edit_team"
                custom
                aria-role="menuitem"
                class="paddingless"
              >
                <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('set-leader', player)"
                  >Definir como líder</fw-button
                >
              </b-dropdown-item>
              <b-dropdown-item
                v-if="player.key != user.key && validations.can_edit_team"
                custom
                aria-role="menuitem"
                class="paddingless"
              >
                <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('delete-player', player)"
                  >Remover</fw-button
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <fw-avatar :user="player" size="lg" class="m-auto" />
          <div class="text-center">
            <p class="font-bold">{{ player.name }}</p>
            <p class="text-gray-500 text-xs">{{ player.email }}</p>
          </div>
          <div class="text-center text-xs text-gray-500">
            Inscrito em {{ player.decision_date ?? team.updated_at | formatDateTime }}
          </div>
        </div>
      </div>
    </fw-panel>

    <fw-panel
      v-if="sport?.type !== 'individual'"
      :title="'Convites pendentes'"
      class="my-5"
      :counter="team.invites?.length ?? 0"
      boxed="xs"
    >
      <RecordInvite
        v-for="invite in team.invites"
        :key="invite.key"
        :invite="invite"
        :allow-resend="validations.can_resend_invites && invite.status == 'pending'"
        :can-remove="validations.can_edit_team && invite.status == 'pending'"
        @resend="$emit('resend-invite', invite)"
        @delete="$emit('delete-player', invite)"
      ></RecordInvite>
      <fw-panel-info v-if="team == null || team.invites.length == 0" type="basic">
        Sem convites pendentes.
      </fw-panel-info>

      <fw-panel-info v-if="totalPlayers < sport?.min_players" type="orange" boxed clean icon class="mt-3">
        <span>Uma equipa tem de ter pelo menos {{ sport?.min_players }} elementos.</span>
      </fw-panel-info>
      <fw-panel-info v-if="totalPlayers > sport?.max_players" type="orange" boxed clean icon class="mt-3">
        <span>Uma equipa só pode ter no máximo {{ sport?.max_players }} elementos.</span>
      </fw-panel-info>
    </fw-panel>

    <fw-panel
      v-if="showMessagesPanel && validations.can_see_messages"
      title="Mensagens"
      :counter="messages.length"
      class="h-full"
    >
      <PanelMessages
        :add-message-button-label="team.status === 'denied' ? 'Adicionar reclamação' : 'Adicionar nova mensagem'"
        :add-message-button-type="team.status === 'denied' ? 'orange' : 'primary'"
        add-message-modal-instructions="Após enviar a mensagem, a mesma não poderá ser editada ou eliminada. Doravante, a sua interação através do painel de mensagens só poderá ter lugar na eventualidade de lhe serem solicitados esclarecimentos."
        :users="users"
        :messages="messages"
        :pagination="paginationMessages"
        :loading="loadingMessages"
        :show-public-private-toggle="false"
        :can-add-message="validations.can_send_messages"
        :default-add-message-is-private="false"
        :ignore-confirm-public-message="true"
        class="h-full"
        @add-message="$emit('add-message', $event)"
        @update-message="$emit('update-message', $event)"
        @delete-message="$emit('delete-message', $event)"
      />
    </fw-panel>

    <fw-panel
      v-if="showCancelButton && validations.can_cancel_team_enrollment"
      title="Operações"
      class="mt-5"
      custom-class="bg-white px-4 py-2"
    >
      <div class="flex items-center flex-1">
        <fw-icon-trash class="text-gray-500 h-6 w-6 mr-2"></fw-icon-trash>
        <fw-label class="flex-1">Cancelar inscrição em equipa</fw-label>
        <fw-button type="link" class="ml-auto" @click.native="$emit('cancel-team-enrollment')">
          Cancelar inscrição
        </fw-button>
      </div>
    </fw-panel>

    <fw-panel-info debug label="Data (raw)">
      <json-viewer :value="{ team, messages, validations, sport }"></json-viewer>
    </fw-panel-info>
  </div>
</template>

<script>
import PanelTeamHeader from '@/components/panels/PanelTeamHeader.vue'
import PanelMessages from '@/fw-modules/fw-core-vue/ui/components/panels/PanelMessages'
import RecordInvite from '@/components/records/RecordInvite'

export default {
  components: {
    PanelTeamHeader,
    PanelMessages,
    RecordInvite
  },
  props: {
    showHeader: {
      type: Boolean,
      default: true
    },
    team: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Object,
      default: () => {}
    },
    messages: {
      type: Array,
      default: () => []
    },
    sport: {
      type: Object,
      default: () => {}
    },
    paginationMessages: {
      type: Object,
      default: () => {}
    },
    loadingMessages: {
      type: Boolean,
      default: false
    },
    validations: {
      type: Object,
      default: () => {}
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
    showMessagesPanel: {
      type: Boolean,
      default: true
    },
    showPlayerDetails: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    totalPlayers() {
      if (this.team) return (this.team.players?.length ?? 0) + (this.validInvites?.length ?? 0)
      return 0
    },

    validInvites() {
      return this.team.invites.filter(el => el.status == 'confirmed' || el.status == 'pending')
    },

    user() {
      return this.$store.getters.getUser
    }
  }
}
</script>
