<template>
  <PanelChats
    minimal
    chat-meeting-only
    :chats="chats"
    :chat-users="chatUsers"
    :all-chat-users="allChatUsers"
    :chat-active="chatActive"
    :editable="true"
    :view-chats-list="false"
    :mobile-view="mobileView"
    :standalone="true"
    :allow-downloads="true"
    :load-chat-on-create="true"
    @load-chat="loadChat"
    @unload-chat="unloadChat"
    @set-chat-messages="setChatMessages"
    @delete-chat-message="deleteChatMessage"
  />
</template>

<script>
import PanelChats from '@/fw-modules/fw-core-vue/chats/components/panels/PanelChats'
import ChatLive from '@/fw-modules/fw-core-vue/chats/mixins/ChatLive'
import ServiceChat from '@/fw-modules/fw-core-vue/chats/services/ServiceChat'

export default {
  components: {
    PanelChats
  },

  mixins: [ChatLive],

  props: {
    chat: {
      type: Object
    },
    mobileView: {
      type: Boolean,
      default: false
    }
  },

  data() {
    this.loadChats()
    const data = this.getChatDefaultData()
    data.loading = true
    data.loadChatOnCreate = true
    data.chatFilters = []
    data.chats = []
    data.destroying = false
    data.viewChatsList = true
    return data
  },

  computed: {
    isMobile() {
      return window.innerWidth < 640
    }
  },

  beforeDestroy() {
    this.destroying = true
    this.unregisterChat()
  },

  created() {
    this.registerChat()
  },

  methods: {
    backTo() {
      if (this.viewChatsList && !this.isMobile) this.$router.back()
      else this.viewChatsList = true
    },
    loadChatAndSetNav($data) {
      this.viewChatsList = false
      this.loadChat($data)
    },
    unloadChatAndSetNav($data) {
      this.viewChatsList = true
      this.unloadChat($data)
    },

    async loadChats() {
      const response = await ServiceChat.getChannel(this.chat.key)
      console.log(response)
      this.setChat(response, true)
      this.setAllChatUnread()
      this.loading = false
    },

    isInChatView() {
      return !this.destroying
    }
  }
}
</script>
