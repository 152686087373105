<template>
  <div class="flex gap-5 items-center mb-5">
    <div class="text-center">
      <TeamAvatar :team="team" :size="size"></TeamAvatar>
    </div>
    <div class="flex-1 flex gap-1 flex-col text-2xl">
      {{ team.name }}
      <div class="flex items-center gap-3">
        <fw-tag v-if="team.status == 'pending'" type="orange"> Pendente </fw-tag>
        <fw-tag v-else-if="team.status == 'submitted'" type="orange"> Submetida </fw-tag>
        <fw-tag v-else-if="team.status == 'approved'" type="primary"> Aprovada </fw-tag>
        <fw-tag v-else-if="team.status == 'cancelled'" type="xlight"> Cancelada </fw-tag>
        <fw-tag v-else-if="team.status == 'denied'" type="light-danger"> Não aprovada </fw-tag>
        <div v-if="team.submitted_at != null" class="text-xs text-gray-500">
          Inscrito em {{ team.submitted_at | formatDateTime }}
        </div>
        <div v-if="(team.is_sealed || team.is_locked) && iAmLeader" class="flex gap-1">
          <fw-icon-lock class="h-4 w-4 text-gray-700"></fw-icon-lock>
          <span class="text-xs text-gray-700">Edição bloqueada</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TeamAvatar from '@/components/TeamAvatar'

export default {
  components: { TeamAvatar },

  props: {
    team: {
      type: Object,
      required: true
    },

    size: {
      type: String,
      default: 'md'
    }
  },

  computed: {
    iAmLeader() {
      return this.team.leader.key === this.$store.getters.getUser.key
    }
  }
}
</script>
